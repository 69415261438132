html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

input[type=file] {
  font-family: 'Fira Code';
  opacity: 0.8;
}
input[type=file]:hover {
  opacity: 1;
}
input[type=file]::file-selector-button {
  font-family: 'Fira Code';
}
input[type=file]::file-selector-button {
  margin: 0.5em;
  border: none;
  background-color: #663;
  padding: 3px 5px;
  border-radius: 0.5em;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
  font-family: 'Fira Code';
}

input[type=file]::file-selector-button:hover {
  background-color: #774;
}


body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Fira Sans';
}

